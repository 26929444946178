exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-makeverifyds-tsx": () => import("./../../../src/pages/makeverifyds.tsx" /* webpackChunkName: "component---src-pages-makeverifyds-tsx" */),
  "component---src-pages-makeverifysc-tsx": () => import("./../../../src/pages/makeverifysc.tsx" /* webpackChunkName: "component---src-pages-makeverifysc-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-verificationsc-tsx": () => import("./../../../src/pages/verificationsc.tsx" /* webpackChunkName: "component---src-pages-verificationsc-tsx" */),
  "component---src-pages-verified-tsx": () => import("./../../../src/pages/verified.tsx" /* webpackChunkName: "component---src-pages-verified-tsx" */),
  "component---src-pages-verify-tsx": () => import("./../../../src/pages/verify.tsx" /* webpackChunkName: "component---src-pages-verify-tsx" */)
}

